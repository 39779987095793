<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        서비스 결제 내역
      </div>
      <div id="sub_title">
        {{userName}} 회원님의 서비스 이용 결제 내역을 조회합니다.
      </div>
    </div>

    <!--    웹용 Grid-->
    <div class="web_grid" style="width:70%; margin-top:50px">
      <table class="w3-table-all pa_table" >
        <thead>
        <tr>
          <th>번호</th>
          <th>상품ID</th>
          <th>상품명</th>
          <th>설명</th>
          <th>결제기간(월/일)</th>
          <th>결제금액</th>
          <th>결제일자</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(row,index) in orderarray" :key="row.billingInfo" >
          <td>{{row.No}}</td>
          <td>{{row.id}}</td>
          <td>{{row.product_name}}</td>
          <td>{{row.description}}</td>
          <td>{{row.additional_month === null ? 0 : row.additional_month}} / {{row.additional_day === null ? 0 : row.additional_day}}</td>
          <td>{{row.price}}</td>
          <td>{{row.date}}</td>
        </tr>
        </tbody>


      </table>

<!--      <b-pagination v-model="currentPage" :total-rows="totalrows" :per-page="perPage" align="center" @click="callPage"></b-pagination>-->

  </div>

  </div>
</template>

<script>

export default {
  name: "order_History",
  data() {

    return {
      report_Comp: 0,
      currentPage: this.$route.params.page >1 ? Number(this.$route.params.page) : 1 ,// 현재 페이지
      perPage: 10, // 페이지당 보여줄 갯수
      totalrows: 1,
      orderarray: [],
      loged: $store.state.token, //로그인 사용자 구분
      userName: localStorage.getItem("user-name")

    };
  },

  mounted(){
     this.getlist()
  },

  methods: {

    getlist() {

      const t =this
      const url = '/billing/paidHisotry'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {

      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const totalItems = res.data.list.length;
        const od = res.data.list.map((item, index) => {
          const billingInfo = JSON.parse(item.billingInfo);
          const formattedPrice = new Intl.NumberFormat('en-US').format(item.price);
          return { No: totalItems - index,
            ...item,
            ...billingInfo,
            price: formattedPrice }; // No 필드 내림차순
        });

        t.orderarray = od
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },


  },


};

</script>

<style scoped>

.w3-table td, .w3-table th, .w3-table-all td, .w3-table-all th {
  font-size:14px;
  text-align: center;
  line-height: 30px;

}

td a {
  cursor:pointer;
}


.pa_table {
  margin-bottom: 20px;
}


.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}


</style>