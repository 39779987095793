<template>
  <div id="main_body" >
<!--    네비게이션-->
    <nav id="mainavi" class="navbar navbar-expand-lg bg-body-tertiary sticky-top" >
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">
          <img :src="logo" alt="Logo" width="153" height="" class="d-inline-block align-text-top" style="margin-left: 11px;
                      margin-right: 49px;">
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
<!--메뉴리스트-->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="padding-top: 5px;">
            <li class="nav-item">
              <router-link to="/svcs/m"  class="nav-link">서비스<sup><i> Beta </i></sup></router-link>
            </li>

            <li class="nav-item">
              <router-link to="/cmnt/kakaoch"  class="nav-link">커뮤니티</router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <router-link to="/cmnt/list/1"  class="nav-link">커뮤니티</router-link>-->
<!--            </li>-->

            <li class="nav-item">
              <router-link to="/company"  class="nav-link">회사소개</router-link>
            </li>

            <li class="nav-item">
              <router-link to="/faq"  class="nav-link">자주묻는 질문</router-link>
            </li>

            <li v-if="testStatus === 'TESTER'" class="nav-item">
              <router-link to="/pay"  class="nav-link">서비스 결제</router-link>
            </li>

            <li class="nav-item">
              <router-link to="/gmail"  class="nav-link">고객센터</router-link>
            </li>

          </ul>
<!--우측로그인 메뉴-->
          <div class="login">
          <router-link to="/login"><button v-if="loged==='notloged'" class="btn btn-outline-success loged" type="submit" >로그인</button></router-link>
          </div>
          <div class="logout" v-if="loged==='loged'">

            <router-link style="text-decoration:none;" to="/accpage/myinfo"><span class="people">
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill-gear" viewBox="0 0 16 16">
                 <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
               </svg>
           {{user}}님 </span>
              <div v-if="bPeriod > currentdate && bPeriod != 'null'" class="period">유료서비스 기한: {{bPeriod}}</div>
              <span class="logon" style="margin-right:10px; font-size: 13px; text-decoration: underline; text-underline-position: under; ">마이페이지</span></router-link>
            <button @click="clearInfo" class="btn btn-outline-success logoutbtn" type="submit" >로그아웃</button>

          </div>
        </div>
      </div>
    </nav>
<!--메인프레임 종료 하단 라우터뷰 -->
    <div id="UserContents" style="padding-top: 0px;">
      <router-view/>
    </div>
  </div>
</template>



<script>

export default {
  name:'UserMain',
  data () {
    return {
      logo: require('./images/AutoClosing02.png'),
      user: $store.state.user_log,
      loged: '',
      bPeriod: $store.state.userPeriod,
      currentdate: $store.state.currentDate,
      testStatus:$store.state.userRole,
    }
  },
  components:{},

  mounted() {
    // 전체 페이지의 유저 로그인 상태를 확인
    if($store.state.user_log != null) {
      this.expireCheck()
    }
    else {this.loged='notloged';

    }

  },

  methods: {
    clearInfo: () => {
      localStorage.removeItem('accseqnm')
      localStorage.removeItem('user-name')
      localStorage.removeItem('access-token')
      localStorage.removeItem('tokenAvailable')
      localStorage.removeItem('mobileAuth')
      localStorage.removeItem('accountRole')
      localStorage.removeItem("billingPeriod")
      // localStorage.clear()
      alert("로그아웃 하였습니다.")
      history.go(0) //현재페이지 다시 로드
      // history.back() // 이전페이지로
      // window.location.href = '/'
    },

    expireCheck(){
      //현재시간 가져요기
      let timezoneOffset = new Date().getTimezoneOffset() * 60000;
      let timezoneDate = new Date(Date.now() - timezoneOffset);
      // console.log(timezoneDate.toISOString().substring(0, 19))

      let C_time = new Date (timezoneDate.toISOString().substring(0, 19))


      // 만료일자 가져온 후 5분 이전으로 만료시간 조정
      const time = $store.state.t_verify
      const givenDate = new Date(time);
      // 5분을 뺄 때, 음수가 발생할 수 있으므로 새로운 날짜를 연산합니다.
      const resultDate = new Date(givenDate);
      resultDate.setMinutes(resultDate.getMinutes() - 5);



      //만료 시점 계산
      let dateA = new Date(C_time);
      let dateB = new Date(resultDate);
      // console.log(dateA)
      // console.log(dateB)
      if (dateA < dateB) {
        //만료시점을 지나지 않음
        // console.log("dateA는 dateB보다 빠릅니다.");
        this.loged='loged';
      } else {
        //만료시점을 지남
        // alert("만료시점을 지났습니다")
        localStorage.clear()
        window.location.href = '/login'
      }

    }

  }
}

</script>

<style>

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.period{
  font-family: Pretendard-Regular;
  color: white;
  display: ruby;
  margin: 0px 7px 0px 2px;
  padding: 4px 10px;
  border-radius: 10px;
  background-color: rgb(111 111 111);
  font-weight: lighter;
  font-size: 11px;
}

#UserContents {
  font-family: Pretendard-Regular;
}

#mainavi {
  padding:7px 2% 7px 35px;
  background-color: white;
  border-bottom: lightgray 1px solid;
  height: 6vh;
}

.nav-item a{
  padding: 7.5px 30px !important;
  font-family: 'Pretendard-Regular';
  font-size:17px;
}

.nav-item a:hover{
  color: #304769 !important;
  background-color: #172c4717;
  border-radius: 7px;
  font-weight: 800;
}

.btn:hover {
  color: white !important;
  background-color: #0e2238 !important;}

input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset ; -webkit-text-fill-color: #000; }
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { transition: background-color 5000s ease-in-out 0s; }

.loged , .logoutbtn {
  font-family: 'Pretendard-Regular' !important;
  height: 35px !important;
  padding: 0px 23px !important;
  color: rgb(14, 34, 56) !important;
  border-color: rgb(14, 34, 56) !important;
  border-radius: 20px !important;
}

/*--모바일 CSS==*/

@import '../../pastyle.css';


</style>