<template>
  <div>
    <div id="board_title" class="row">
      <div id="IpBanner">
        <div  class="fcadescript mb-5">
          <div class="container content-space-2 content-space-lg-3">
            <div class="row">
              <div class="col-lg-12 mb-9 mb-lg-0 titles">

              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sub_title">

      </div>
    </div>

    <div class="row">
      <!--    서비스 메뉴-->
      <div class="col-sm-4 mb-3 mb-sm-0">
        <div class="card card_deco">
          <div class="Main_Contents" >
            <div style="margin-bottom: 10px"><span>주요 서비스</span></div>

            <div class="card mb-3" style="max-width: 100%; box-shadow: 5px 5px 6px #d9d9d9a3; border:1px solid #f9f9f9; margin-bottom:30px !important;">
              <div class="row g-0">
                <div class="col-md-1" style="width: 3%; background-color: #383535; border-radius: 5px 0px 0px 5px;">

                </div>
                <div class="col-md-11">
                  <div class="card-body">
                    <h5 class="card-title" style="margin-top: 0px">Lease Automation</h5>
                    <p class="card-text" style="font-size: 14px; color:#939393; margin-bottom: 5px">
                      리스 회계처리 자동화 서비스 입니다.
                    </p>
                    <p class="card-text" style="margin-left: 10px">
                      <small class="text-body-secondary">
                        <span style="color:#427aa7">●</span><span><router-link to="/svcs/leaseg"> 사용자 가이드</router-link></span>
                        <span style="margin: 0px 10px;">|</span>
                        <span style="color:#489cc3">●</span><span><router-link to="/svcs/leasef"> 리스양식 산출<sup><i> v1.0.3 </i></sup></router-link></span>
                        <span style="margin: 0px 10px;">|</span>
                        <span style="color:#d1e0eb">●</span><span><router-link to="/svcs/leaser"> 리스회계처리 자동화</router-link></span>
                      </small></p>
                  </div>
                </div>
              </div>
            </div>


            <div class="card mb-3" style="max-width: 100%; box-shadow: 5px 5px 6px #d9d9d9a3; border:1px solid #f9f9f9; margin-bottom:30px !important;">
              <div class="row g-0">
                <div class="col-md-1" style="width: 3%; background-color:#10457f; border-radius: 5px 0px 0px 5px; ">

                </div>
                <div class="col-md-11">
                  <div class="card-body">
                    <h5 class="card-title" style="margin-top: 0px">DSD Footing Automation</h5>
                    <p class="card-text" style="font-size: 14px; color:#939393; margin-bottom: 5px">
                      DSD 검토 자동화 서비스 입니다.
                    </p>
                    <p class="card-text" style="margin-left: 10px">
                      <small class="text-body-secondary">
                        <span style="color:#427aa7">●</span><span><router-link to="/svcs/dsdfg"> 사용자 가이드</router-link></span>
                        <span style="margin: 0px 10px;">|</span>
                        <span style="color:#489cc3">●</span><span><router-link to="/svcs/dsdfr"> 감사/검토보고서 Footing</router-link></span>
                        <span style="margin: 0px 10px;">|</span>
                        <span style="color:#d1e0eb">●</span><span><router-link to="/svcs/dsdfr2"> 사업보고서 Footing</router-link></span>


                      </small></p>
                    <h5 class="card-title" style="margin-top: 25px">DSD Automation</h5>
                    <p class="card-text" style="font-size: 14px; color:#939393; margin-bottom: 5px">
                      DSD 작성 자동화 서비스 입니다.
                    </p>
                    <p class="card-text" style="margin-left: 10px">
                      <small class="text-body-secondary">
                        <span style="color:#427aa7">●</span><span><router-link to="/svcs/dsdatg"> 사용자 가이드</router-link></span>
                        <span style="margin: 0px 10px;">|</span>
                        <span style="color:#489cc3">●</span><span><router-link to="/svcs/dsdatr"> DSD Auto</router-link></span>

                      </small></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3" style="max-width: 100%; box-shadow: 5px 5px 6px #d9d9d9a3; border:1px solid #f9f9f9; margin-bottom:30px !important;">
              <div class="row g-0">
                <div class="col-md-1" style="width: 3%; background-color:rgb(167 167 167); border-radius: 5px 0px 0px 5px; ">

                </div>
                <div class="col-md-11">
                  <div class="card-body">
                    <h5 class="card-title" style="margin-top: 0px">XBRL Automation</h5>
                    <p class="card-text" style="font-size: 14px; color:#939393; margin-bottom: 5px">
                      XBRL 작성 자동화 서비스 입니다.
                    </p>
                    <p class="card-text" style="margin-left: 10px">
                      <small class="text-body-secondary">
                        <span style="color:#427aa7">●</span><span><router-link to="/svcs/Const"> 준비중...</router-link></span>


                      </small></p>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--    업데이트 정보-->
      <div class="col-sm-4 update_area">
        <div class="card card_deco">
          <div class="Main_Contents" >
            <div style="margin-bottom: 10px"><span>주요 업데이트</span></div>

            <div class="card mb-3" style="max-width: 100%; min-height:244px; box-shadow: 5px 5px 6px #d9d9d9a3; border:1px solid #f9f9f9; margin-bottom:30px !important;">
              <div class="row g-0">
                <div class="col-md-1 card_side_C" style="width: 3%;  min-height:244px; background-color: #d1d1d1; border-radius: 5px 0px 0px 5px;">

                </div>
                <div class="col-md-11">
                  <div class="card-body">

                    <div class="accordion" id="Updateaccordion">
                      <div class="accordion-item" v-for="(row, index) in updates" :key="row.content_id">
                        <h2 class="accordion-header">
                          <button class="update_style accordion-button collapsed" type="button" @click="toggleAccordion(index)" :aria-expanded="activeIndex === index" :aria-controls="`collapse-${index}`">
                            <span style="width:80px !important">{{ row.created_date }}</span><span class="up_str">|</span>{{ row.category }}<span class="up_str">|</span>{{ row.title }}
                          </button>
                        </h2>
                        <div :id="`collapse-${index}`" class="accordion-collapse collapse" :class="{ 'show': activeIndex === index }">
                          <div class="accordion-body pt-2">
<!--                            {{ row.context }}-->
                            <span v-html="formattedContext(row.context)"></span>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Upd from "@/data/noticedata.json";

export default {
  data(){
    let upDateList = Upd.noticedata.sort((a, b) => {
      return b.content_id - a.content_id;
    }); // 내림차순

    return {
      activeIndex: null,
      updates: upDateList,


    };

  },

  mounted() {
    console.log($store.state.moduleStatus)
  },
  methods: {
    toggleAccordion(index) {
      this.activeIndex = (this.activeIndex === index) ? null : index;
    },
    formattedContext(context) {
      // Replace line breaks with <br> tags
      return context.replace(/\n/g, '<br>');
    }
  }
}
</script>

<!--<script setup>-->
<!--import { ref } from 'vue';-->

<!--const activeIndex = ref(null);-->

<!--const toggleAccordion = (index) => {-->
<!--  activeIndex.value = activeIndex.value === index ? null : index;-->
<!--};-->
<!--</script>-->

<style scoped>
.update_area {
  width: 650px;
}

.accordion-item{
  border: none;

}
.accordion-button::after{
 background-size:15px;
}
.accordion-button:first-of-type {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.accordion-button:last-of-type {
border-bottom-left-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}
.accordion-body{
  font-size: 13px;
  margin-top: 10px;
  text-align: justify;
  color:#5796bd;
}
.up_str{
  margin: 0px 10px;
  color: #a9a9a9
}

.update_style {
  padding:0px;
  font-family: Pretendard-Regular;
  font-size: 13px;
}
#IpBanner {
  background-image: url(@/components/CommonPage/images/service2.png);
  background-repeat: no-repeat; /* 배경이미지X */
  background-size: cover; /* 요소를 비율에 맞게 커버 */
   /* 이미지를 요소의 정가운데로 처리 */
//background-attachment: fixed; /* 스크롤바 움직일때 이미지가 따라다님 */
  border-radius: 15px !important;
}

.fcadescript {
 margin-bottom: 20px !important;
}

.titles {
  height:40px;
}

.card-title {
  font-family: Pretendard-Regular !important;
  font-size: 16px !important;
}

.text-body-secondary a {
  text-decoration: none;
}

.card_deco {
  border:none;
}

</style>