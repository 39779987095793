<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        사용자 가이드
      </div>
      <div id="sub_title">
        DSD 합계검증, 내용검토 작업을 회계법인에서는 DSD Footing이라고 표현합니다.<br>
        이 가이드에서는 DSD Footing 모듈의 이용 방법을 설명합니다.
      </div>
    </div>
    <div class="Main_Contents" >
      <div class="l_menus" style="width:60%; ">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                DSD Footing 모듈 사용자 영상 가이드
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" >
              <div class="accordion-body">
                <div class="video-container">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/7aeyLOuVZlk?si=VPqBUUxjsc8snOL6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <br>
                각 양식에 대한 상세 설명은 하단의 항목별 가이드 내용을 참조하시길 바랍니다.
                <br><br>



              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                DSD Footing | 준비와 진행
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" >
              <div class="accordion-body">
                <br>
                회계팀 결산의 최종 단계는 재무제표 및 주석 작성입니다.<br>
                그리고 이러한 결산의 결과를 전자공시시스템에 공시하기 위해 진행하는 최종 작업이 바로 DSD 작성입니다.<br>
                재무제표작성의무는 회사에 있으므로 DSD 작성은 회사가 작성하여야 하며, 일정 기준을 가진 회사는 이를 회계감사 전에 증권선물위원회에 제출해야 합니다.
                <br><br>
                각사에서 준비하는 DSD의 경우 자동검증이 되지 않아 작성에도 어려움이 있으며, 회계감사 과정에서 발생하는 수정사항을 일일이 찾아 대응하기에는 시간적/능력적 어려움이 상당히 많이 발생합니다.
                <br><br>
                AutoClosing에서는 DSD Footing(DSD 검토)을 통해 DSD 작성 이후 단순 합계 검증 및 계정간의 대사에 시간을 효과적으로 절약할 수 있는 검토 서비스를 제공합니다.
                한시가 다급한 기말결산 또는 분반기 결산 시기에 조금이라도 시간을 절약하여 보다 가치 있는 작업을 진행하셨으면 합니다.<br><br>

                <strong>DSD Footing 검토 진행</strong><br><br>
                ▶ 먼저 검토를 위한 DSD 파일을 준비 합니다.<br>
                ▶ DSD 검토 자동화 페이지의 [DSD Footing UPLOAD]버튼을 클릭하여 준비한 파일을 업로드 합니다.<br>
                ▶ 검토 결과 메일이 송부 됩니다.<br><br>



              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                DSD Footing | 감사/검토보고서 및 사업보고서
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" >
              <div class="accordion-body">
                <br>
                AutoClosing에서는 감사/검토보고서와 사업보고서 DSD Footing을 지원하고 있습니다.
                <br><br>
                감사/검토보고서는 [연결]과 [별도]를 별개의 파일로 작성하는 반면, 사업보고서는 DSD 파일 내 [연결]과 [별도]가 같이 작성되어 있어 산출물의 구성이 다릅니다.<br>
                이에 따라 아래와 같이 별도의 페이지에서 검토 서비스를 제공하고 있습니다.
                <br><br>
                <strong>DSD 검토 자동화 서비스</strong><br><br>

                ▶ 감사/검토보고서 검토 : 감사보고서 및 검토보고서, 사전재무제표 DSD Footing 서비스 이용이 가능합니다. (별도/연결/분기/반기/기말 모두 가능)<br>
                ▶ 사업보고서 검토 : 사업보고서 DSD Footing 서비스 이용이 가능합니다. (분기/반기/기말 모두 가능)<br>
                <br>
                각 화면에서 해당하는 DSD 파일을 업로드 해 주시면, 검토 결과를 제공해 드립니다.
                <br>
                [연결] 및 [별도] 중 검토에 오류가 발생하는 경우, 결과물 제공 메일에서의 결과 파일 다운로드 링크 대신 오류에 대한 내용을 제공해드릴 예정입니다.
                <br><br>
                이용자께서는 각각의 페이지에서 DSD 검토 요청을 진행하시기 바랍니다.
                <br><br>

                <br>



              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                DSD Footing | 전기 DSD 발송
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" >
              <div class="accordion-body">
                <br>
                DSD Footing은 개별 회사의 DSD내용을 분석하여 구축된 서비스임에 따라 일반적인 회사와 계정과목명, 표의 구조 등에 차이가 있는 경우에는 검증이 이루어지지 않을 수 있습니다.<br>
                이를 방지하기 위하여 사전에 공시된 전기 DSD를 제출하여 주시면 개발팀에서 이를 분석하여 당기 DSD의 검증이 최대한 구현이 되도록 하는 서비스를 제공하고 있습니다.
                <br><br>
                정보보호 차원에서 아직 공시가 되지 않은 자료는 수령하고 있지 않으며, 혹시라도 수령하는 경우 이를 즉시 삭제하고 있으니 양해 부탁드립니다.<br>
                귀사의 원활한 Footing Service 제공을 원하시는 분은 아래의 링크로 전기 DSD 제출 진행 부탁드립니다.<br>
                <br><br>
                <strong>전기 DSD 제출 진행</strong><br><br>
                ▶ 아래의 버튼을 클릭하면 전기 DSD 파일을 업로드 화면이 표시됩니다.<br>
                ▶ 사용자 로그인 후, 전기 DSD 업로드 [동의] 체크 후 파일을 업로드 합니다.<br><br>
                <div class="ins_btn" type=button @click="insReq" style="display: inline">
                  전기 DSD 검토 요청</div>
                <br><br>



              </div>
            </div>
          </div>

        </div>

      </div>
      <br><br><br><br><br><br><br>

      <!--      실행페이지 링크-->

      <!--      <div>-->
      <!--        <h4 class="pb-2 border-bottom" style="font-family:Pretendard-Regular;">리스 (Lease) Automation</h4>-->
      <!--      </div>-->

      <!--      <div class="Guide_row row row-cols-1 row-cols-md-2  g-7 py-5">-->
      <!--        <div class="col d-flex2 flex-column align-items-start gap-2">-->
      <!--          <div class="l_menus">-->
      <!--            <router-link to="/svcs/leasef" class="guidebox" >-->
      <!--              <svg viewBox="0 0 24 24" class="icons" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M22.91,6.953,12.7,1.672a1.543,1.543,0,0,0-1.416,0L1.076,6.953a.615.615,0,0,0,0,1.094l10.209,5.281a1.543,1.543,0,0,0,1.416,0L22.91,8.047a.616.616,0,0,0,0-1.094Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M.758,12.75l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,12.75" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M.758,17.25l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,17.25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>-->
      <!--              <p>리스자료 양식 DOWN</p>-->
      <!--            </router-link>-->
      <!--            리스회계 양식 내용을 확인 하시고<br>상황에 맞는 자료를 입력하세요. <span style="font-size: 12px; font-weight: 800;">(클릭하여 PAGE 이동)</span>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="col">-->
      <!--          <div class="l_menus">-->
      <!--            <router-link to="/svcs/leaser" class="guidebox" >-->
      <!--              <svg viewBox="0 0 24 24" class="icons" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M22.91,6.953,12.7,1.672a1.543,1.543,0,0,0-1.416,0L1.076,6.953a.615.615,0,0,0,0,1.094l10.209,5.281a1.543,1.543,0,0,0,1.416,0L22.91,8.047a.616.616,0,0,0,0-1.094Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M.758,12.75l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,12.75" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M.758,17.25l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,17.25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>-->
      <!--              <p>리스회계처리 자동화</p>-->
      <!--            </router-link>-->
      <!--            작성 완료된 리스 입력자료을 UPLOAD하여<br>자동화 서비스를 진행합니다.-->
      <!--            <span style="font-size: 12px; font-weight: 800;">(클릭하여 PAGE 이동)</span>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

    </div>


  </div>
</template>

<script>


export default {
methods:{
  insReq: function(){
    window.open('/dsdchk','_blank',"menubar=no, toolbar=no, width=450px,height=550px,top=200,left=700")

  },
}

}
</script>

<style scoped>

.video-container {
  width: 80%; /* DIV의 가로 길이의 80% */
  position: relative;
  padding-top: 43%; /* 16:9 비율을 유지하기 위해 세로 padding 설정 (9 ÷ 16 ≈ 0.5625 -> 56.25%) */
  margin: 0 auto; /* 가운데 정렬 */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 부모의 가로길이 100% */
  height: 100%; /* 부모의 높이에 맞춤 */
}

.guide_pdf{
  margin-bottom:10px;
  background-color: #0e2238;
  width:120px; height:30px;
  text-align:center;
  padding-top: 4px;
  color:white;
  border-radius: 20px;
  cursor: pointer;
}
.guide_pdf p:hover {
  color:#c6cfdd !important;
}
.Guide_row{
  width:60%;
  padding-bottom: 0px !important;
}

.bot_contents span {
  font-size: 11px;
  font-weight: 300;
  color:grey;
  line-height: 20px;
}

.l_menus{
  margin-bottom: 65px;
  font-size: 14px;
  margin-top: 80px;
}
.icons{
  display: flex;
  width: 53px;
  background-color: #fff;
  height: 44px;
  padding: 6px;
  margin: auto 8px;
  border-radius: 25px;
}
.guidebox{
  display: flex;
  background-color: #dee2e6;
  width:265px;
  height:55px;
  border-radius: 30px;
  margin-bottom: 10px;
}
.l_menus a{
  text-decoration: none;
}

.guidebox p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 10px;
}

.guide_title {
  font-family: Pretendard-Regular !important;
}

.accordion-button:not(.collapsed) {
  background-color: #dee2e6;
  color:black;
  font-weight:bold;
}

.guide_red {
  color: #C20000FF;
}

.ins_btn{
  width: 130px;
  height: 35px;
  background: #00295d;
  text-align: center;
  padding-top: 8px;
  font-size: 14px;
  margin-right: 15px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}


</style>