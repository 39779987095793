<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        사용자 가이드
      </div>
      <div id="sub_title">
        AutoClosing은 계산이 복잡하고 시간이 많이 소요되는 리스 회계처리를 자동화 하는 시스템을 제공하고 있습니다.<br>
        이 가이드에서는 리스 자동화 서비스의 이용 방법을 자세히 설명합니다.
      </div>
    </div>
    <div class="Main_Contents" >
      <div class="l_menus" style="width:60%; ">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                리스모듈 사용자 영상 가이드
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" >
              <div class="accordion-body">
                <div class="video-container">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/H0vpZhu8jB8?si=KnJcjT1be476GPJV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <br>
                각 양식에 대한 상세 설명은 하단의 항목별 가이드 내용을 참조하시길 바랍니다.
                <br><br>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                1. 리스양식 산출
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" >
              <div class="accordion-body">
                <br>
                리스 회계처리 자동화를 위해서는 각 회사의 현황에 따른 리스 양식을 산출하여 해당 양식에 리스 자료를 입력하셔야
                합니다.<br><br>
                리스 양식을 구분한 이유는 복잡한 리스 회계처리를 이용하지 않는 회사는 보다 간편한 양식으로 손쉽게 작성할 수 있도록
                하기 위함입니다. 판단이 어려우신 경우에는 기본값으로 양식을 산출하시고 자료 입력하시는 과정에서 필요한 기능이
                있으시면 해당 양식을 다시 산출하여 새로운 양식에 작성하시는 방법을 권유드립니다.<br><br>
                현재 이용 가능한 리스 기능은 아래와 같습니다.
                <br><br>
                <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 97%;
                          border-radius: 9px;" src="@/components/CommonPage/images/p11.png"><br><br>
                <strong>리스 자동화 회계처리 기능은 유저의 요청에 의해 지속적으로 업데이트 될 예정입니다.</strong>
                <br><br>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                2. 리스양식 작성 - 기본양식
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" >
              <div class="accordion-body">
                <br>
                리스양식산출 페이지에서 어떠한 선택도 하지 않은 기본 양식에 대한 설명을 진행합니다.<br>
                기본 양식이 아닌 다른 선택항목에 대해서는 각 항목별로 설명자료가 준비되어 있으므로 확인 부탁드립니다.<br><br>
                <br>
                기본 양식은 3개의 시트로 이루어져 있습니다. 각 시트에서 입력하실 내용은 아래와 같습니다.<br><br>
                <div style="font-size: 13px;">
                ▶ Index : 회사명, 결산일, 회사신용등급을 입력합니다.<br>
                ▶ List : 구분, 리스개시일, 기간, 지급리스료 등을 입력합니다.<br>
                ▶ Change or Extension : 리스변경 또는 리스연장이 발생하는 경우 입력합니다. 변경/연장 관리 차원의 시트입니다.
                </div>
                <br><br>
                <div>
                  <b-tabs content-class="mt-3">
                    <b-tab title="Index" active>
                      <br>
                      <div style="margin-left: 22px;">
                        아래의 양식에 회사명, 결산일, 회사신용등급을 입력합니다.<br>
                        회사신용등급은 회사채 I(공모사채) / 무보증을 기준으로 AAA부터 BBB-까지의 신용등급을 지원합니다.<br>
                      </div>
                      <img class="guide_img01" style="padding: 21px;
                                  margin: 23px;
                                  border: 1px solid #c5c5c5;
                                  border-radius: 9px;" src="@/components/CommonPage/images/1_1_Index.png">
                      <br><br> <br><br>

                    </b-tab>
                    <b-tab title="List">
                      <br>
                      <div style="margin-left: 22px;">
                        아래의 양식에 구분, 리스개시일, 기간, 지급리스료 등을 입력합니다. 각 항목별로 하단에 설명 참조 부탁드립니다.<br>
                        아직 베타서비스 중인 관계로 최대 50건의 리스계산을 제공하고 있습니다.
                      </div>
                      <img style="padding: 21px;
                          margin: 20px;
                          border: 1px solid #c5c5c5;
                          width: 92%;
                          border-radius: 9px;" src="@/components/CommonPage/images/2_1_List.png">

                      <div style="margin-left:50px; line-height: 30px;">
                        - 구분 : 부동산 / 차량 / 기타의 구분을 지원합니다. 3가지 중 하나를 선택하여 입력하시기 바랍니다.<br>
                        - 리스개시일 : 리스개시일을 입력 바랍니다.<br>
                        - 기간 : 리스기간을 월단위로 입력바랍니다.<br>
                        - 중도해지일 : 만기일 전에 중도해지하는 경우에는 중도해지일을 입력바랍니다. 중도해지일은 결산일보다
                        이전이어야
                        합니다.<br>
                        - 해지월 리스료 : 중도해지로 인하여 마지막 달의 리스료가 일할계산 되는 경우 이 금액을 입력합니다.<br>
                        <span style="font-size: 13px; color:#2828c9; margin-left:100px;">(예시 : 월 리스료가 3,000,000원인데, 10일날 해지하게 되어 1,000,000원만 지급하게 되는 경우 1,000,000원
                입력)</span><br>
                        - 지급리스료 : 매월 기준의 지급리스료를 입력합니다.<br>
                        - 상각비 구분 : 매출원가 / 판관비 중 하나를 선택합니다.<br>
                        - 비고 : 각 리스 관련 비고사항을 입력합니다.<br>
                      </div>
                      <br><br><br>
                      <div style="margin-left: 22px;">
                      <strong>작성양식 우측에는 상세 설명 사항이 준비되어 있습니다. 입력 시 관련 내용의 참고를 부탁드립니다.</strong>
                      </div>
                      <br>
                      <img style="padding: 21px;
                          width: 92%;
                          margin: 20px;
                          border: 1px solid #c5c5c5;
                          border-radius: 9px;" src="@/components/CommonPage/images/table02.png">

                      <br><br> <br><br>

                    </b-tab>
<!--                    <b-tab title="Change or Extension">-->
<!--                      <br>-->
<!--                      <div style="margin-left: 22px;">-->
<!--                        아래의 양식에 변경 또는 연장된 리스의 세부내용을 입력바랍니다. 각 항목별로 하단에 설명 참조 부탁드립니다.<br>-->
<!--                        변경과 연장의 관리차원에서 작성하는 시트이므로 기존 리스의 내용이 회색 행에 자동으로 입력되며, 변경 및 연장된-->
<!--                        리스를 기존 리스 하단에 입력하시면 됩니다.-->
<!--                      </div>-->
<!--                      <img style="padding: 21px;-->
<!--                          margin: 20px;-->
<!--                          border: 1px solid #c5c5c5;-->
<!--                          width: 92%;-->
<!--                          border-radius: 9px;" src="@/components/CommonPage/images/3_1_CE.png">-->

<!--                      <div style="margin-left:50px; line-height: 30px;">-->
<!--                        - 변경/연장 : 해당 리스의 변경 또는 연장 여부를 입력 바랍니다.<br>-->
<!--                        - 리스개시일 : 리스개시일을 입력 바랍니다.<br>-->
<!--                        - 기간 : 리스기간을 월단위로 입력바랍니다.<br>-->
<!--                        - 중도해지일 : 만기일 전에 중도해지하는 경우에는 중도해지일을 입력바랍니다. 중도해지일은 결산일보다-->
<!--                        이전이어야-->
<!--                        합니다.<br>-->
<!--                        - 해지월 리스료 : 중도해지로 인하여 마지막 달의 리스료가 일할계산 되는 경우 이 금액을 입력합니다.<br>-->
<!--                        <span style="font-size: 13px; color:#2828c9; margin-left:100px;">(예시 : 월 리스료가 3,000,000원인데, 10일날 해지하게 되어 1,000,000원만 지급하게 되는 경우 1,000,000원-->
<!--                입력)</span><br>-->
<!--                        - 지급리스료 : 매월 기준의 지급리스료를 입력합니다.<br>-->
<!--                        - 상각비 구분 : 매출원가 / 판관비 중 하나를 선택합니다.<br>-->
<!--                        - 비고 : 각 리스 관련 비고사항을 입력합니다.<br>-->
<!--                      </div>-->
<!--                      <br><br><br>-->
<!--                      <div style="margin-left: 22px;">-->
<!--                        <strong>작성양식 우측에는 상세 설명 사항이 준비되어 있습니다. 입력 시 관련 내용의 참고를 부탁드립니다.</strong>-->
<!--                      </div>-->
<!--                      <br>-->
<!--                      <img style="padding: 21px;-->
<!--                          width: 92%;-->
<!--                          margin: 20px;-->
<!--                          border: 1px solid #c5c5c5;-->
<!--                          border-radius: 9px;" src="@/components/CommonPage/images/table03.png">-->

<!--                      <br><br> <br><br>-->

<!--                    </b-tab>-->
                  </b-tabs>
                </div>

              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                3. 리스양식 선택항목별 상세 정보
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" >
              <div class="accordion-body">
                <br>
                리스 회계처리 자동화를 위한 양식 자료의 산출을 위한 항목별 상세 가이드 입니다.<br>
                아래 양식 선택안에 따른 상세 입력 항목을 확인 하시고 자료를 작성하시기 바랍니다.(항목을 클릭하여 확인하세요.) <br>
                <br>

                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_2" aria-expanded="false" aria-controls="collapse3_2">
                         1. 사용권자산 감가상각비 계정분류
                      </button>
                    </h2>
                    <div id="collapse3_2" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 사용권자산 감가상각비 매출원가 비율 안분을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        유형자산의 감가상각비는 자산이 생산활동에 직접 사용되는지 여부에 따라 매출원가 또는 판매비와관리비로 구분됩니다.
                        이를 판단하기 위해서는 자산의 사용목적, 사용방법 등을 종합적으로 고려하여 판단해야 합니다. 사용권자산의 감가상각도
                        유형자산의 감가상각을 준용하므로 위의 설명과 같이 각 사용권자산의 사용목적 등에 따라 매출원가 또는
                        판매비와관리비로 구분됩니다.<br><br>
                        AutoClosing에서는 기본값으로 각 사용권자산별로 매출원가 또는 판관비를 선택할 수 있도록 설정되어 있습니다. 다만,
                        일부 회사는 생산활동과 그 외의 활동에 모두 사용하는 경우가 존재하므로 이를 고려하여 비율에 따라 매출원가, 판관비에
                        안분하는 기능을 제공하고 있습니다.<br><br>
                        <strong>기본값 1)</strong><br>
                        감가상각비 : 판매관리비 또는 매출원가 중 선택 적용 시, 아래와 같이 각 자산별로 판관비 또는 매출원가를
                        입력할 수 있습니다.<br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g04.jpg">
                        <br><br><br><br>
                        <strong>선택안 2)</strong><br> 감가상각비 판매관리비와 매출원가 비율안분 선택 적용 시, 아래와 같이 각 자산별로 판관비 비율을 입력할 수
                        있습니다.<br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g05.jpg">
                        <br><br>

                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_3" aria-expanded="false" aria-controls="collapse3_3">
                         2. 리스부채 계산방식
                      </button>
                    </h2>
                    <div id="collapse3_3" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body" >
                        리스양식산출 페이지에서 리스부채 계산방식(일별 계산)을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스부채의 계산은 일반적인 엑셀양식으로는 월별계산으로 계산되어집니다. 이는 엑셀수동작업으로는 많은 리스자산에
                        대하여 일별효과까지 고려하기에 한계가 있기 때문입니다.<br><br>
                        AutoClosing은 기본값으로는 일반적인 엑셀양식과 동일하게 월별계산을 지원합니다. 하지만 월별계산을 하게 되는
                        경우에는 월 도중에 가입 시, 지급임차료의 처리에 오류가 발생하게 됩니다. 예를 들어 지급임차료가 월 300,000원이
                        발생하였는데, 가입일이 16일이라 첫 월 이용일이 15일인 경우에는 150,000원임에도 불구하고 지급임차료에서 300,000
                        원이 조정되게 됩니다.<br><br>
                        이러한 이유로 AutoClosing에서는 선택안으로 일별 리스계산을 지원하고 있습니다. 일별 리스계산을 선택 시,
                        리스개시월과 리스마감월의 일수에 따라 지급임차료 금액이 조정됩니다. 이는 보다 완벽한 리스회계처리를 지원하나,
                        과거에 이와 같이 리스회계처리를 하지 않은 회사의 경우에는 과거와의 차이가 발생할 수 있고, 일부 상황의 경우 계산이
                        필요이상 복잡해질 수 있으므로 회계 담당자의 선택이 필요한 부분입니다.<br><br>
                        위 선택안은 리스회계처리 담당자의 판단에 따라 결정해주시면 됩니다.<br><br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_4" aria-expanded="false" aria-controls="collapse3_4">
                         3. 리스보증금 리스계산
                      </button>
                    </h2>
                    <div id="collapse3_4" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 리스보증금 리스계산을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스계약시 이용자가 지급하는 리스보증금에 대하여 기준서는 이를 금융상품으로 인지하고 있습니다. 즉, 단순히
                        보증금으로 지급한 금액은 원금(현재가치 할인된 금액)과 이자(현재가치 할인 이자)로 구분되어 있다고 봅니다. 지급한
                        보증금을 현재가치로 할인한 금액으로 인식해야 한다고 판단하고 있으며, 그에 따라 이자로 구분된 금액은 리스료를
                        선지급한 선급리스료로 보고 있습니다.<br><br>
                        이러한 기준서의 해석에 따라, 보증금으로 지급한 금액 중 이자에 해당되는 부분은 선급리스료의 성격으로 사용권자산으로
                        포함되어야 합니다. 하지만 이러한 계산을 엑셀수동작업으로 진행하기에는 어려움이 있습니다.<br><br>
                        AutoClosing은 이러한 리스보증금 회계처리를 자동화하여 서비스하고 있습니다. 기본값으로는 리스보증금 계산을
                        적용하지 않고 있으나, 선택안 2) 보증금 리스계산 포함을 선택 시, List 양식에 아래와 같이 보증금 열이 추가되어
                        리스보증금 계산을 자동화할 수 있도록 설정되어 있습니다.<br><br>
                        선택안을 통해 보증금 리스계산 자동화 서비스를 이용바랍니다.<br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g06.jpg">
                        <br><br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_5" aria-expanded="false" aria-controls="collapse3_5">
                         4. 복구충당부채 리스계산
                      </button>
                    </h2>
                    <div id="collapse3_5" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 복구충당부채 리스계산을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스계약 만료시 이용자가 지급해야하는 복구충당금은 이용자가 부담해야 하는 원가의 추정치에 포함되기 때문에 이를
                        사용권자산에 포함시켜야 합니다. 다만, 이 역시 사용권자산과 별도로 관리하여야 하고, 리스 변경, 리스 취소 등의
                        상황에서 수동으로 관리하기가 어렵기 때문에 이를 별도로 처리하지 않는 경우가 있습니다.<br><br>
                        AutoClosing은 이러한 복구충당부채 회계처리를 자동화하여 서비스하고 있습니다. 기본값으로는 복구충당부채 계산을
                        적용하지 않고 있으나, 선택안 2) 복구충당부채 리스계산 포함을 선택 시, List 양식에 아래와 같이 복구충당부채 열이
                        추가되어 복구충당부채 계산을 자동화할 수 있도록 설정되어 있습니다. 그리고 실제 리스 만기 시, 지급한 복구비를
                        입력하게 되면 자동으로 회계처리를 제공하고 있습니다.<br><br>
                        선택안을 통해 복구충당부채 리스계산 자동화 서비스를 이용바랍니다.<br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g07.jpg">
                        <br><br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_6" aria-expanded="false" aria-controls="collapse3_6">
                         5. 외화리스계산
                      </button>
                    </h2>
                    <div id="collapse3_6" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 외화리스계산을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스계약 중에서는 원화인 KRW가 아니라 USD, EUR, JPY, CNH 등의 외화로 리스료를 지급하는 경우가 있습니다. 이러한
                        외화리스계약이 존재하는 경우, 사용권자산은 비화폐성이므로 별도의 회계처리를 진행할 필요가 없으나, 리스부채의
                        경우는 화폐성부채이므로 다른 외화부채와 같이 회계처리가 필요합니다.<br><br>
                        AutoClosing은 이러한 외화리스 회계처리를 자동화하여 서비스하고 있습니다. 기본값으로는 외화리스 계산을 적용하지
                        않고 있으나, 선택안 2) 외화리스계산 포함을 선택 시, List 양식에 아래와 같이 통화 열이 추가되어 외화를 선택하고,
                        외화리스계산을 자동화할 수 있도록 설정되어 있습니다. 현재 제공하고 있는 외화는 USD, JPY, CNH, GBP, VND, IDR,
                        MYR, INR, AUD, MXN이며, 이용자의 요청에 따라 지속적으로 추가할 예정입니다.<br><br>
                        선택안을 통해 외화리스계산 자동화 서비스를 이용바랍니다.<br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g08.jpg">
                        <br><br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_7" aria-expanded="false" aria-controls="collapse3_7">
                         6.  기간별 리스료 직접 입력
                      </button>
                    </h2>
                    <div id="collapse3_7" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 기간별 리스료 직접 입력을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스계약으로 계약기간 동안 동일한 금액을 일정하게 납부하는 경우가 대부분입니다만, 가끔은 기간별로 확정된 다른
                        금액의 리스료를 기간에 따라 지급하는 경우가 있습니다. 그러한 예시로 연도별로 5%씩 증가하는 리스료이거나, 1년차는
                        100,000원, 2년차는 200,000원 등과 같이 확정된 금액을 정해진 기간에 지급하는 리스입니다. 이와 같이 고정리스료이나
                        기간별로 다른 리스료가 적용되는 리스는 수동으로 계산하기에는 어려움이 있습니다.<br><br>
                        AutoClosing은 이러한 기간별 리스료 회계처리를 자동화하여 서비스하고 있습니다. 기본값으로는 기간별 리스료 직접 입력
                        계산을 적용하지 않고 있으나, 선택안 2) 기간에 따라 고정리스료 변동을 선택 시, List 양식에 아래와 같이 리스료 변동
                        열이 추가되어 Y를 선택 시, 지급리스료 칸이 회색으로 표시되고 Rent 시트에서 직접 각 시기의 리스를 입력하도록 자동화
                        서비스를 지원하고 있습니다.<br><br>
                        선택안을 통해 기간별 리스료 자동화 서비스를 이용바랍니다.<br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g09.jpg">
                        <br><br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_8" aria-expanded="false" aria-controls="collapse3_8">
                         7. 전대리스 계산
                      </button>
                    </h2>
                    <div id="collapse3_8" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 전대리스 계산을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스계약을 통해 임차한 사용권자산을 여러 이유로 다른 임차인에게 다시 재임대하는 경우가 있습니다. 이를 전대리스라고
                        하며, 이러한 전대리스도 리스계산에 포함되어야 합니다. 전대리스를 통해 계산된 사용권자산은 기존의 사용권자산에서
                        차감해야하며, 리스부채는 회사가 수령할 채권이므로 리스채권으로 인식됩니다. 하지만 리스자산별로 전대리스를
                        관리하고 회계처리하는 것은 수동으로 관리하기에는 어려움이 있습니다.<br><br>
                        AutoClosing은 이러한 전대리스를 자동화하여 서비스하고 있습니다. 기본값으로는 전대리스 계산을 적용하지 않고
                        있으나, 선택안 2) 전대리스 리스계산 포함을 선택 시, Sub Lease라는 시트가 생성되어 전대리스를 입력하면, 전대리스
                        자동화가 지원되는 서비스를 제공하고 있습니다. Sub Lease 작성 시 유의하실 점은 전대리스자산의 상위리스항목을 B열과
                        C열에 입력해야 한다는 점입니다. 그리고 D열의 S-No. 항목을 통해 전대리스를 구분합니다.<br><br>
                        선택안을 통해 전대리스 자동화 서비스를 이용바랍니다.<br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g10.jpg">
                        <br><br>

                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_9" aria-expanded="false" aria-controls="collapse3_9">
                         8. 리스 인센티브 계산
                      </button>
                    </h2>
                    <div id="collapse3_9" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 리스인센티브 계산을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        리스계약을 진행하는 과정에서 사전에 임대인으로부터 리스료를 할인 받거나 지원금을 제공받는 경우가 있습니다. 이를
                        리스인센티브라고 하며, 이는 리스계산에서 조정되어 관리되어야 합니다. 하지만, 개별적으로 이를 관리하기에는 어려움이
                        있습니다.<br><br>
                        AutoClosing은 이러한 리스 인센티브를 자동화하여 서비스하고 있습니다. 기본값으로는 리스 인센티브 계산을 적용하지
                        않고 있으나, 선택안 2) 리스 인센티브 계산 포함을 선택 시, List 양식에 아래와 같이 리스 인센티브 열이 생성되어 리스
                        인센티브를 입력하면, 리스인센티브 회계처리 자동화가 지원되는 서비스를 제공하고 있습니다.<br><br>
                        선택안을 통해 리스인센티브 자동화 서비스를 이용바랍니다.<br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 90%;
                          border-radius: 9px;" src="../images/lease/g11.jpg">
                        <br><br>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button guide_title Sub_desc collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3_10" aria-expanded="false" aria-controls="collapse3_9">
                        9. 해외 자회사 리스 계산
                      </button>
                    </h2>
                    <div id="collapse3_10" class="accordion-collapse collapse" >
                      <div class="accordion-body Sub_desc_body">
                        리스양식산출 페이지에서 해외 자회사 리스 계산을 선택한 경우의 양식에 대한 설명을 진행합니다.<br><br>
                        K-IFRS 연결재무제표 작성 과정에서 자회사가 리스 회계처리를 자체적으로 하지 못하여 모회사에서 이를 대신 작성하고 관리해야 하는 경우가 있습니다.
                        <br>국내 종속회사의 경우는 그 계산이 어렵지 않으나, 해외 자회사의 경우에는 통화기준이 다르기 때문에 주석 작성 등에 어려움이 있는 경우가 있습니다.
                        <br><br>
                        AutoClosing은 이러한 해외 자회사 리스 계산을 자동화하여 서비스하고 있습니다.
                        기본값으로는 해외 자회사 계산을 적용하지 않고 있으나, 선택안 2) 해외 자회사 리스 계산 포함을 선택 시,
                        Index 양식에 아래와 같이 리스 자회사 통화 열이 생성되어 해당 자회사의 통화를 선택하면, 해외 자회사 리스 회계처리 자동화가 지원되는 서비스를 제공하고 있습니다.
                        <br><br>
                        선택안을 통해 해외 자회사 리스계산 자동화 서비스를 이용바랍니다.
                        <br><br>
                        <img  style="padding: 10px;
                          margin: 10px;
                          border: 1px solid #c5c5c5;
                          width: 35%;
                          border-radius: 9px;" src="../images/lease/g12.png">
                        <br><br>
                      </div>
                    </div>
                  </div>
                  <br>

                </div>
                <br><br>
              </div>
            </div>
          </div>

        </div>

      </div>
      <br><br><br><br><br><br><br>


    </div>


  </div>
</template>

<script>


export default {


}
</script>

<style scoped>

.video-container {
  width: 80%; /* DIV의 가로 길이의 80% */
  position: relative;
  padding-top: 43%; /* 16:9 비율을 유지하기 위해 세로 padding 설정 (9 ÷ 16 ≈ 0.5625 -> 56.25%) */
  margin: 0 auto; /* 가운데 정렬 */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 부모의 가로길이 100% */
  height: 100%; /* 부모의 높이에 맞춤 */
}

.guide_pdf{
  margin-bottom:10px;
  background-color: #0e2238;
  width:120px; height:30px;
  text-align:center;
  padding-top: 4px;
  color:white;
  border-radius: 20px;
  cursor: pointer;
}
.guide_pdf p:hover {
  color:#c6cfdd !important;
}
.Guide_row{
  width:60%;
  padding-bottom: 0px !important;
}

.bot_contents span {
  font-size: 11px;
  font-weight: 300;
  color:grey;
  line-height: 20px;
}

.l_menus{
  margin-bottom: 65px;
  font-size: 14px;
  margin-top: 80px;
}
.icons{
  display: flex;
  width: 53px;
  background-color: #fff;
  height: 44px;
  padding: 6px;
  margin: auto 8px;
  border-radius: 25px;
}
.guidebox{
  display: flex;
  background-color: #dee2e6;
  width:265px;
  height:55px;
  border-radius: 30px;
  margin-bottom: 10px;
  }
.l_menus a{
  text-decoration: none;
}

.guidebox p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 10px;
}

.guide_title {
  font-family: Pretendard-Regular !important;
}

.accordion-button:not(.collapsed) {
  background-color: #dee2e6;
  color:black;
  font-weight:bold;
}

.guide_red {
  color: #C20000FF;
}

.Sub_desc {
  padding:6px 6px 6px 20px;
  color: #367ecf;
}

.Sub_desc:not(.collapsed) {
  background-color: #e8edff !important;
}

.Sub_desc_body {
  padding: 50px;
}

</style>